import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/Layout"
import LoginForm from "../components/LoginForm/LoginForm"
import { mapStateToProps } from "../reduxMap"
import { connect } from "react-redux"
import Login from "../components/Login/Login"

let LayoutIndexPage = ({ user }) => {
  return (
    <Layout>
      <Login />
    </Layout>
  )
}

export default LayoutIndexPage
