import React, { useEffect } from "react"
import AttendanceBox from "../AttendanceBox/AttendanceBox"
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from "../../reduxMap"
import { navigate } from "gatsby"
import LoginForm from "../LoginForm/LoginForm"
import axios from "axios"
import { config } from "../../config"
import { readFromLocalStorage, login as logIn } from "../../util"

let Login = ({ user, openSnackbar, setLoading, stopLoading, setUser }) => {
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")

  async function login() {
    let payload = { username, password }

    setLoading("Logging you in...")
    await logIn({
      username,
      password,
      setUser,
      setLoading,
      stopLoading,
      openSnackbar,
    })
    // await axios
    //   .post("https://etcool-api.herokuapp.com/login", payload)
    //   .then(res => res.data)
    //   .then(result => {
    //     if (result.success === true) {
    //       setLoading("Login success full. Please wait...")

    //       setUser({
    //         data: result.data,
    //         username: payload.username,
    //         password: payload.password,
    //       })
    //     } else {
    //       stopLoading()
    //       openSnackbar({
    //         snackbarText: result.message,
    //         snackbarType: "error",
    //       })
    //     }
    //   })
    //   .catch(error => console.log(error))
  }

  useEffect(() => {
    stopLoading()
    if (user && user.session) {
      setLoading("Please wait dude...")
      navigate("/dashboard")
    } else {
      let session = readFromLocalStorage("session")
      let username = readFromLocalStorage("username")
      let password = readFromLocalStorage("password")

      if (session) {
        setUser({
          data: {
            session,
          },
          username,
          password,
        })
      }
    }
  }, [user])

  return (
    <>
      <LoginForm
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        login={login}
      />
    </>
  )
}

Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

export default Login
